var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "위험성평가 시나리오 종합 목록",
            columns: _vm.grid.columns,
            data: _vm.summary.data,
            merge: _vm.grid.merge,
            gridHeight: _vm.grid.height,
            rowKey: "riskAssessmentTargetId",
            selection: "multiple",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customCol" &&
                  props.row["sopImprovementIds"] &&
                  props.row["ibmStepNames"]
                    ? _vm._l(
                        _vm.$comm.ibmTagItems(props.row),
                        function (item, index) {
                          return _c(
                            "q-chip",
                            {
                              key: index,
                              staticStyle: {
                                "margin-bottom": "4px !important",
                              },
                              attrs: {
                                color: item.color,
                                clickable: true,
                                "text-color": "white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.linkClick(item, props.row)
                                },
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(item.title) + " "),
                              _c("c-assess-tooltip", {
                                attrs: {
                                  ibmTooltip: item.ibmTooltip,
                                  ibmClassCd: item.ibmClassCd,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      )
                    : col.name === "src"
                    ? [
                        _c("q-btn", {
                          attrs: {
                            round: "",
                            unelevated: "",
                            size: "6px",
                            color: "amber",
                            icon: "search",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.srcClick(props.row, props.pageIndex)
                            },
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "선택", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }